<template>
  <div id="Journal">
    <div id="Journals">
      <div class="container">
        <h3>Journals</h3>
        <hr>

        <div class="row">
          <div class="col-md-3">
            <a target="_blank" href="https://www.journals.elsevier.com/global-environmental-change"><img class="img-responsive" src="../../assets/static/img/Journals/globalEnvironmentalChange.jpg"></a>
          </div>

          <div class="col-md-9">
            <div>
              <strong><a target="_blank" href="https://www.journals.elsevier.com/global-environmental-change">Global Environmental Change</a></strong><br>
              <p>
                <label>ISSN</label>:0959-3780<br>
                <label>Impact Factor</label>: 11.16 (2021) <br>
                <label>Description</label>: Global Environmental Change is a peer-reviewed international journal publishing high
                quality, theoretically and empirically rigorous articles, which advance knowledge about the human and policy dimensions of
                global environmental change. The journal interprets global environmental change to mean the outcome of processes that are manifest
                in localities, but with consequences at multiple spatial, temporal and socio-political scales. The journal is interested in articles which
                have a significant social science component. <br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-3">
            <a target="_blank" href="https://journals.sagepub.com/home/dhg"><img class="img-responsive" src="../../assets/static/img/Journals/DialoguesinHumanGeography.png"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://journals.sagepub.com/home/dhg">Dialogues in Human Geography</a></strong><br>
              <p>
                <label>ISSN</label>: 2043-8206<br>
                <label>Impact Factor</label>: 27.00 (2021) <br>
                <label>Description</label>: The primary aim of Dialogues in Human Geography
                is to stimulate open and critical debate on the philosophical, methodological and pedagogic
                foundations of geographic thought and praxis. It publishes articles, with responses, which seek
                to critique present thinking and praxis and set the agenda for future avenues of geographic thought,
                empirical research and pedagogy. Dialogues is theoretical in orientation, forward looking, and seeks to
                publish original and innovative work that pushes the boundaries of geographical theory,
                praxis and pedagogy through a unique (in Geography) and innovative format of open peer commentary.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.sciencedirect.com/journal/earth-science-reviews"><img class="img-responsive" src="../../assets/static/img/Journals/Earth-Science Reviews.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.sciencedirect.com/journal/earth-science-reviews">Earth-Science Reviews</a></strong><br>
              <p>
                <label>ISSN</label>: 0012-8252<br>
                <label>Impact Factor</label>: 12.04 (2021) <br>
                <label>Description</label>Covering a much wider field than the usual specialist journals, Earth Science Reviews publishes review articles dealing with all aspects of Earth Sciences, and is an important vehicle for allowing readers to see their particular interest related to the Earth Sciences as a whole.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.sciencedirect.com/journal/remote-sensing-of-environment"><img class="img-responsive" src="../../assets/static/img/Journals/RSE.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.sciencedirect.com/journal/remote-sensing-of-environment">Remote Sensing of Environment</a></strong><br>
              <p>
                <label>ISSN</label>: 0034-4257<br>
                <label>Impact Factor</label>: 13.85 (2021) <br>
                <label>Description</label>: Remote Sensing of Environment (RSE) serves the Earth
                observation community with the publication of results on the theory, science, applications, and
                technology of remote sensing studies. Thoroughly interdisciplinary, RSE publishes on terrestrial,
                oceanic and atmospheric sensing. The emphasis of the journal is on biophysical and quantitative
                approaches to remote sensing at local to global scales.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.tandfonline.com/toc/recg20/current"><img class="img-responsive" src="../../assets/static/img/Journals//economicgeography.png"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/recg20/current">Economic Geography</a></strong><br>
              <p>
                <label>ISSN</label>: 2520-1417<br>
                <label>Impact Factor</label>: 14.92 (2021) <br>
                <label>Description</label>: Economic Geography is an internationally
                peer-reviewed journal committed to publishing original research that makes leading-edge
                advances within and beyond the sub-discipline of economic geography. We publish high-quality,
                substantive work that is theoretically rich and informed by empirical evidence that deepens our understandings of
                the geographical drivers and implications of economic processes, broadly considered. We welcome submissions that focus
                on a wide range of topics, deploy primary evidence in support of theoretically significant interventions, and contribute
                key insights regarding pressing economic, social, development, and environmental questions and challenges. <br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.journals.elsevier.com/isprs-journal-of-photogrammetry-and-remote-sensing"><img class="img-responsive" src="../../assets/static/img/Journals//ISPRS.jpg"></a>
          </div>

          <div class="col-md-9">
            <div>
              <strong><a target="_blank" href="https://www.journals.elsevier.com/isprs-journal-of-photogrammetry-and-remote-sensing">ISPRS Journal of Photogrammetry and Remote Sensing</a></strong><br>
              <p>
                <label>ISSN</label>: 0924-2716<br>
                <label>Impact Factor</label>: 11.77 (2021) <br>
                <label>Description</label>: The ISPRS Journal of Photogrammetry and Remote Sensing (P&RS) is the official journal of the International Society for Photogrammetry and Remote Sensing (ISPRS). The Journal provides a channel of communication for scientists and professionals in all countries working in the many disciplines that employ photogrammetry, remote sensing, spatial information systems, computer vision, and related fields. The Journal is designed to serve as a source reference and archive of advancements in these disciplines.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://journals.sagepub.com/home/phg"><img class="img-responsive" src="../../assets/static/img/Journals/Progress in Human Geography.png"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://journals.sagepub.com/home/phg">Progress in Human Geography</a></strong><br>
              <p>
                <label>ISSN</label>: 0309-1325<br>
                <label>Impact Factor</label>: 7.60 (2021) <br>
                <label>Description</label>: Progress in Human Geography is the peer-review journal of choice for
                those wanting to know about the state of the art in all areas of human geography research - philosophical,
                theoretical, thematic, methodological or empirical. Concerned primarily with critical reviews of current research,
                PiHG enables a space for debate about questions, concepts and findings of formative influence in human geography.<br>
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.journals.elsevier.com/landscape-and-urban-planning"><img class="img-responsive" src="../../assets/static/img/Journals/landscapeandUrbanPlanning.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.journals.elsevier.com/landscape-and-urban-planning">Landscape and Urban Planning</a></strong><br>
              <p>
                <label>ISSN</label>: 0169-2046<br>
                <label>Impact Factor</label>: 8.12 (2021) <br>
                <label>Description</label>: Landscape and Urban Planning is an international journal aimed at
                advancing conceptual, scientific, and applied understandings of landscape in order to promote sustainable
                solutions for landscape change. Landscapes are visible and integrative social-ecological systems with variable
                spatial and temporal dimensions. They have expressive aesthetic, natural, and cultural qualities that are perceived and valued by people
                in multiple ways and invite actions resulting in landscape change.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.sciencedirect.com/journal/environmental-modelling-and-software"><img class="img-responsive" src="../../assets/static/img/Journals/ENVIRON MODELL SOFTW.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div class="m-fsz col-md-9 col-xs-12 m-center">
              <strong><a target="_blank" href="https://www.sciencedirect.com/journal/environmental-modelling-and-software">Environmental Modelling & Software </a></strong><br>
              <p>
                <label>ISSN</label>: 1364-8152<br>
                <label>Impact Factor</label>: 5.47 (2021) <br>
                <label>Description</label>: Environmental Modelling & Software publishes contributions, in the form of research articles, reviews, introductory overviews, and position papers on advances in the area of environmental modelling and software. Our aim is to improve our capacity to represent, understand, predict or manage the behaviour of natural environmental systems, including air, water, and land components, at all practical scales, and to communicate those improvements to a wide scientific and professional audience.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.journals.elsevier.com/computers-environment-and-urban-systems"><img class="img-responsive" src="../../assets/static/img/Journals/computers,EnvironmentandUrbanSystems.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.journals.elsevier.com/computers-environment-and-urban-systems"> Computers, Environment and Urban Systems</a></strong><br>
              <p>
                <label>ISSN</label>: 0198-9715 <br>
                <label>Impact Factor</label>: 6.45 (2021) <br>
                <label>Description</label>: Computers, Environment and Urban Systems is an interdisciplinary journal publishing cutting-edge
                and innovative computer-based research on urban systems, systems of cities, and built and natural environments ,
                that privileges the geospatial perspective.
              </p>
            </div>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.mdpi.com/journal/remotesensing"><img class="img-responsive" src="../../assets/static/img/Journals/remotesensing.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.mdpi.com/journal/remotesensing">Remote Sensing</a></strong><br>
              <p>
                <label>ISSN</label>: 2072-4292<br>
                <label>Impact Factor</label>: 5.35 (2021) <br>
                <label>Description</label>: Remote sensing is a technology that engages electromagnetic sensors to measure and monitor changes in the earth’s surface and atmosphere. Normally this is accomplished through the use of a satellite or aircraft. Remote Sensing, in its third edition, seamlessly connects the art and science of earth remote sensing with the latest interpretative tools and techniques of computer-aided image processing. Newly expanded and updated, this edition delivers more of the applied scientific theory and practical results that helped the previous editions earn wide acclaim and become classroom and industry standards.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=8859"><img class="img-responsive" src="../../assets/static/img/Journals/groRSL.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://ieeexplore.ieee.org/xpl/RecentIssue.jsp?punumber=8859">IEEE Geoscience and Remote Sensing Letters</a></strong><br>
              <p>
                <label>ISSN</label>: 1545-598X<br>
                <label>Impact Factor</label>: 5.34 (2021) <br>
                <label>Description</label>: IEEE Geoscience and Remote Sensing Letters (GRSL) is a monthly publication for short papers (maximum length 5 pages) addressing new ideas and formative concepts in remote sensing as well as important new and timely results and concepts. Papers should relate to the theory, concepts and techniques of science and engineering as applied to sensing the earth, oceans, atmosphere, and space, and the processing, interpretation, and dissemination of this information. The technical content of papers must be both new and significant. Experimental data must be complete and include sufficient description of experimental apparatus, methods, and relevant experimental conditions. GRSL encourages the incorporation of "extended objects" or "multimedia" such as animations to enhance the shorter papers.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.tandfonline.com/toc/tgis20/current"><img class="img-responsive" src="../../assets/static/img/Journals/IJGIS.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/tgis20/current">International Journal of Geographical Information Science</a></strong><br>
              <p>
                <label>ISSN</label>: 1365-8816<br>
                <label>Impact Factor</label>: 5.15 (2021) <br>
                <label>Description</label>: The aim of International Journal of Geographical Information Science is to provide a forum for the exchange of original ideas, approaches, methods and experiences in the field of GIScience.
                International Journal of Geographical Information Science covers the following topics:Innovations and novel applications of GIScience in natural resources, social systems and the built environment Relevant developments in computer science, cartography, surveying, geography, and engineering Fundamental and computational issues of geographic information
                The design, implementation and use of geographical information for monitoring, prediction and decision making.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="http://www.sciencedirect.com/science/journal/01436228"><img class="img-responsive" src="../../assets/static/img/Journals/Applied Geography.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="http://www.sciencedirect.com/science/journal/01436228">Applied Geography</a></strong><br>
              <p>
                <label>ISSN</label>: 0143-6228<br>
                <label>Impact Factor</label>: 4.73 (2021) <br>
                <label>Description</label>: Applied Geography is a journal devoted to the publication of research which utilizes geographic approaches (human, physical, nature-society and GIScience) to resolve human problems that have a spatial dimension. These problems may be related to the assessment, management and allocation of the world's physical and/or human resources. The underlying rationale of the journal is that only through a clear understanding of the relevant societal, physical, and coupled natural-humans systems can we resolve such problems.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.tandfonline.com/toc/tjde20/current"><img class="img-responsive" src="../../assets/static/img/Journals/IJDE.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/tjde20/current">International Journal of Digital Earth</a></strong><br>
              <p>
                <label>ISSN</label>: 1753-8947<br>
                <label>Impact Factor</label>: 4.60 (2021) <br>
                <label>Description</label>: International Journal of Digital Earth is a journal focusing on the theories, technologies, applications and societal implications of Digital Earth.
                International Journal of Digital Earth is the official journal of the International Society for Digital Earth.
                The journal is a response to the Digital Earth initiative and itsaim to improve social conditions, protect the environment, and support future sustainable development.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://onlinelibrary.wiley.com/journal/15384632"><img class="img-responsive" src="../../assets/static/img/Journals/Geographical Analysis.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://onlinelibrary.wiley.com/journal/15384632">Geographical Analysis</a></strong><br>
              <p>
                <label>ISSN</label>: 1538-4632<br>
                <label>Impact Factor</label>: 3.57 (2021) <br>
                <label>Description</label>: Geographical Analysis publishes geographical theory, model building, and quantitative methods to geographers and scholars in a wide spectrum of related fields.
                First in its specialty area and one of the most frequently cited journals in geography,
                Geographical Analysis has, since 1969, published significant advances in geographical theory,
                model building, and quantitative methods to geographers and scholars in a wide spectrum of related fields.
                Traditionally, mathematical and nonmathematical articulations of geographical theory, and statements and discussions of
                the analytic paradigm are published in the journal. Spatial data analyses and spatial econometrics and statistics are strongly represented.
              </p>
            </div>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class='col-md-3'>
            <a target="_blank" href="https://www.tandfonline.com/toc/tagi20/current"><img class="img-responsive" src="../../assets/static/img/Journals/AnnualofGIS.jpg"></a>
          </div>

          <div class='col-md-9'>
            <div>
              <strong><a target="_blank" href="https://www.tandfonline.com/toc/tagi20/current">Annals of GIS</a></strong><br>
              <p>
                <label>ISSN</label>: 1947-5683<br>
                <label>Description</label>: Annals of GIS is an open access journal that publishes interdisciplinary research on theory, methods, development and applications in geo-information science.
                The journal aims to connect researchers in the region to one another and to the wider, international scientific community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop style="visibility-height:1400"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'Journal'
}
</script>

<style lang="less" scoped>
#Journal {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

.img-responsive {
  display: block;
  margin-right: auto;
  margin-left: 3.3rem;
  margin-top: auto;
  max-width: 100%;
}

.vs-row {
  margin: 2.2rem 0;
}

p {
  font-size: 1.1rem;
  margin-bottom: 0px;
}

a {
  color: #117450;
  text-decoration: none;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

label {
  font-weight: 600;
}

.row {
  align-items: center;
  margin: 2.2rem 0;
  line-height: 2rem;
}
</style>