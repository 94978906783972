<template>
  <div id="_2021_3">
    <div id="Minutes-of-the-first" class="cos-modal">
      <h3><strong>Semi-annual Meeting of Board Members, AGA-YGWG</strong></h3>
      <small class="home-date">Aug 30, 2021<br></small>
      <hr>
      <div class="pic">
        <img style="max-width: 80%;" src="../../../assets/static/img/Events/Semi annual1.png">
      </div>
      <br>
      <ul>
        <li><strong>ATTENDANCE</strong><br>
          <p>
            Prof. Min Chen, the chairman of the working group, chaired the meeting, the secretary-general and six regional contact persons attended the meeting:
            Li He, Zhuo Wu, Dhritiraj Sengupta, Debajit Datta, Suh-hee Choi, Gulomjon Umirzakov, Nguyen Van Hoang, Berkehan Bakir, and Kai Zhang.<br><br>
          </p>
        </li>
        <li><strong>AGENDA ITEMS</strong><br>
        </li>
        <p>
          1. Prof. Min Chen reported to all board members the progress in member registration.
          AGA-YGWG now has 166 members from 13 countries. And further reported recent activities and news, this includes: <br>
        </p>

        <ul>
          <li>
            “The 2nd national forum on geographic modeling and simulation (and the launch ceremony of the training course on domestic geographical analysis models)” on Jan 16-17, 2021
          </li>
          <li>
            “The first training course on geographical analysis models” on Jan 16-17, 2021
          </li>
          <li>
            The forthcoming sub-forum “‘Geography +’
            young scholars sub-forum under interdisciplinary integration” which will be hold on “China Conference on Geography 2021”, Nov 12, 2021.
          </li>
        </ul>

        <div class="pic">
          <img style="max-width: 80%;" src="../../../assets/static/img/Events/Semiannual2.png"><br><br>
        </div>
        <p>
          2. Prof. Min Chen also shared information on the special issues of four journals, that is:<br>
        </p>
        <ul>
          <li>Applied Energy | SI: Energy digitization with spatial intelligence</li>
          <li>Transactions in GIS | SI: Virtual Geographic Environment</li>
          <li>Frontiers in Public Health (Environmental Health and Exposome) | SI:
            Smart Urban Environmental Health from Multi-Scale, Multimedia, Multi-Exposure, Multi-Target Perspectives</li>
          <li>Water | SI: Advances in Hydroinformatics for Water Data Management and Analysis</li>
        </ul>
        <br>
        <p>
          3. All board members provided suggestions on regular collection of news and activities and organizing regional workshops. And discussed plans regarding the forthcoming“First Workshop of Asian Young Geographers” and other future activities.
        </p>
        <div class="pic">
          <img style="max-width: 80%;" src="../../../assets/static/img/Events/Semiannual3.png">
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_3'
}
</script>

<style lang="less" scoped>
#_2021_3 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  height: 2700px;
  text-align: justify;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  width: 100rem;
  display: table-cell;
  text-align: center;
}
</style>