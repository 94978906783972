<template>
  <div id="_2021_1">
    <div id="Call-for-Abstracts" class="cos-modal">
      <h3><strong>The First Asian Young Geographers Workshop Held Online</strong></h3>
      <small class="home-date">December 18th–19th, 2021<br></small>
      <hr>
      <p> &nbsp;&nbsp;&nbsp;&nbsp;On December 18th–19th, 2021, the First Asian Young Geographers Workshop was held online. Initiated by the Young Geographers Working Group of the Asian Geographical Association (AGA-YCWG), the Workshop was jointly organized by AGA-YGWG, Young and Early Career Geographers Task Force of the International Geographical Union (IGU-YECG) and Working Committee for Young Geographers of the Geographical Society of China (GSC- WCYG). Under the theme “Young Geographers’ Response to Challenges in Asia”, the workshop attracted about 600 participants from 30 countries and regions worldwide. After call for paper within two weeks, a total of 213 abstracts and papers were received. As a result, 178 of them have been selected for oral presentations in 14 sessions of different topics, such as Building Resilience to Poverty Reduction in Asia, Hydro-Climatic Extreme Events in Mountain Regions, Geographical Indications Environment and Sustainability, etc.<br></p>

      <div style="margin-top: 2%;margin-bottom: 2%;" class="pic">
        <img src="../../../assets/static/img/Events/The-First-Asian.png" style="width: 80%">
      </div>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;The opening ceremony kicked off on the morning of 18th. Prof. Min Chen, Chair of the AGA-YGWG declared the workshop open. Prof. Chul Sue Hwang, Vice President of the AGA, President of the Korean Geographical Society, Dr. Komali Yenneti, Chair of the IGU-YECG, Dr. Zhuo Wu, Vice Secretary General of GSC-WCYG delivered welcome speeches on behalf of the three organizers respectively. Then, Ms. Xuanzi Zhang, Secretary and Treasurer of the AGA introduced the preparatory work of the 5th Asian Conference on Geography (5th ACG) and invited all participants to get together again at the 5th ACG, to be held during September 5th -9th, 2022 in Thai Nguyen, Vietnam.</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;During the two-day online event, the workshop featured keynote talks given by 7 distinguished geographers, including Young Geographers in Asia and Sustainable Development by Prof. Yukio Himiyama, Past President of the IGU, Coastal and Estuarine Wetlands in Anthropocene by Prof. Michael Meadows, President of the IGU, Sustainable Water Resources Management: Issues and Challenges by Prof. Suresh Chand Rai, Vice President of the AGA and Secretary General of the National Association of Geographers, India, Climate Change in South Asia: Implications for Land by Prof. Safdar Ali Shirazi, President of the Pakistan Geographical Association, Smart Technology and Sustainable Cities and Communities by Prof. Anthony Yeh, Academician of the Chinese Academy of Sciences, Professor of University of Hong Kong, Big Data and Geospatial Technologies for Sustainable Cities Research by Prof. Mei-po Kwan, Chinese University of Hong Kong, Ecosystem Responses to Gradual Environment Change by Prof. Shuli Niu, Institute of Geographic Sciences and Natural Resources Research, Chinese Academy of Sciences.</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;The workshop concluded on the afternoon of 19th. Prof. Li He, Secretary General of AGA-YGWG chaired the closing ceremony. Prof. Min Chen made a summary report on the achievements of the workshop. Then, Prof. Guoyou Zhang, Vice President and Secretary General of the GSC announced the winners of the Excellent Paper Awards. After recommendations by session chairs first and thorough review and deliberation by the awarding committee afterwards, finally 9 papers were selected for the awards. 3 recipients including Dr. Fan Zhang from Massachusetts Institute of Technology, USA, Dr. Yangyang Li from National University of Singapore and Dr. Vu Thi Bac from VNUHCM-University of Social Sciences and Humanities, Vietnam were invited to give their acceptance speeches.</p>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;Under the Covid-19 pandemic which has been lasting for two years, the workshop was initiated to serve as a new platform for young geographers in Asia and elsewhere of the world, to facilitate sharing, exchanges and collaborations on their latest research, in order to seek responses to address nowadays’ challenges we are facing.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_1'
}
</script>

<style lang="less" scoped>
#_2021_1 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  height: 115rem;
  text-align: justify;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  width: 100rem;
  display: table-cell;
  text-align: center;
}
</style>