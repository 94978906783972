<template>
  <div id="top">
    <div>
      <a href="http://www.aga-ygwg.com/"><img class="Title" src="../assets/static/img/logo/KeyLogo.png"></a>
    </div>
    <div class="text-space">
      <p>Young Geographer Working Group (YGWG)</p>
      <p>Asian Geographical Association (AGA)</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top'
}
</script>

<style lang="less" scoped>
#top {
  margin-top: 1.3rem;
  text-align: center;
  line-height: 1.3rem;
  font-size: 1.5rem;
  word-spacing: 0.2rem;
}

.text-space {
  margin-top: 0.6rem;
}
</style>