<template>
  <div id="JobsOpportunities">
    <div>
      <div>
        <br>
        <strong>Three faculty positions in Social/Cultural Geographies addressing contemporary crises of sustainability in Asia</strong>
        <hr>
        <p>National University of Singapore<br>Singapore</p>
        <a href="https://fass.nus.edu.sg/geog/2021/02/25/job-opportunities-three-faculty-positions-in-social-cultural-geographies/?fbclid=IwAR1EXNq8y8md2gHmjR2C07L6KjKJGhDlhGs_XLbTwNZMooTzNjZpQanQPoc" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Postdoctoral Research Position in Department of Geographic Science</strong>
        <hr>
        <p>Beijing Normal University<br>Zhuhai, China</p>
        <a href="https://rsgyy.bnu.edu.cn/yjjg/gtkjghybdcyjzx/gtrczp/97750.html" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Postdoctoral Research Position in Department of Computer Science and Engineering at ZJNU</strong>
        <hr>
        <p>Zhejiang Normal University<br>Jinhua, China</p>
        <a href="https://www.researchgate.net/jobs?page=1&regions=" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Doctoral Student in urban sustainability science</strong>
        <hr>
        <p>University of Helsinki<br>Helsinki, Finland</p>
        <a href="https://www.helsinki.fi/en/open-positions/doctoral-student-in-urban-sustainability-science" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Lecturer in Geographical Information Science</strong>
        <hr>
        <p>Queen's University Belfast<br>Belfast, United Kingdom</p>
        <a href="https://hrwebapp.qub.ac.uk/tlive_webrecruitment/wrd/run/ETREC107GF.open?VACANCY_ID=070278DjjB&WVID=6273090Lgx&LANG=USA" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Vacancy of Academic Posts for the field of Land Resources Planning at National Chengchi University, TAIWAN, China</strong>
        <hr>
        <p>National Chengchi University<br>Taipei, Taiwan, China</p>
        <a href="https://www.researchgate.net/jobs?page=1&regions=" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Assistant Professor in Big Data Science for Natural Resources</strong>
        <hr>
        <p>University of Twente<br>Enschede, Netherlands</p>
        <a href="https://www.utwente.nl/en/organisation/careers/!/2020-36/assistant-professor-in-big-data-science-for-natural-resources?utm_source=researchgate&utm_medium=xml&utm_campaign=hr-researchgate-vacancy-xml" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Our Earth Science Group has vacancies for Two Postdocs Vacancy number 1639</strong>
        <hr>
        <p>Netherlands Institute for Space Research, Utrecht<br>Utrecht, Netherlands</p>
        <a href="https://www.researchgate.net/jobs?page=1&regions=" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <div>
      <div>
        <br>
        <strong>Group Leader Position in Geochemistry of Surface Waters (Tenure Track)</strong>
        <hr>
        <p>Eawag: Das Wasserforschungs-Institut des ETH-Bereichs<br>Kastanienbaum (Lucerne), Switzerland</p>
        <a href="https://apply.refline.ch/673277/0806/pub/5/index.html" target="_blank" style="color: white; text-decoration: none;">
          <el-button round type="success">View</el-button>
        </a>
      </div>
    </div>

    <br>

    <i class="el-icon-link"><a href="https://www.researchgate.net/jobs?page=1&regions=" target="_blank">&nbsp;&nbsp;More Information</a></i>

  </div>
</template>

<script>
export default {
  name: 'JobsOpportunities'
}
</script>

<style lang="less" scoped>
#JobsOpportunities {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

strong {
  color: #117450;
}

h3 {
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

a {
  // display: block;
  width: 4rem !important;
  text-decoration: none;
  color: #117450;
}
</style>