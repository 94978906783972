<template>
  <div id="BoardMembers">
    <div class="row">
      <h4 class="cos  -strong">Chair and Secretary</h4>
      <hr>
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/MinChen.jpg">
          </div>
          <div class="col-md-9 col-xs-12">
            <strong>Prof. Min Chen</strong><br>
            <strong>Chair of YGWG of AGA</strong>
            <p>PhD, Professor <br>
              Nanjing Normal University, China<br>
              Links: <a href="http://schools.njnu.edu.cn/geog/person/min-chen" target="_blank" style="color: orange;">http://schools.njnu.edu.cn/geog/person/min-chen</a><br>
              Email: <a href="mailto:chenmin0902@163.com" style="color: orange;"> chenmin0902@163.com </a>
            </p>
          </div>
        </div>
      </div>

      <div class="container">
        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12 ">
            <img class="img-responsive" src="../assets/static/img/Members/LiHe.png">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Li He</strong><br>
            <strong>Secretary of YGWG of AGA</strong>
            <p>PhD, Associate Professor<br>
              Xi’an Jiaotong University, China<br>
              Links: <a href="http://rwxy.xjtu.edu.cn/info/1091/6259.htm" target="_blank" style="color: orange;">http://rwxy.xjtu.edu.cn/info/1091/6259.htm</a><br>
              Email: <a href="mailto:lihegeo@xjtu.edu.cn" style="color: orange;"> lihegeo@xjtu.edu.cn </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <h4 class="cos-strong">Board Member (Alphabetically ordered by last name)</h4>
    <hr>
    <div class="col-12 cos-modal2">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/DhritirajSengupta.jpg">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Dhritiraj Sengupta</strong><br>
            <p>PhD, Post-Doc Fellow<br>
              State Key Laboratory of Estuarine and Coastal Research, East China Normal University, China<br>
              Links: <a href="https://www.linkedin.com/in/dhritiraj-sengupta-ph-d-682648167" target="_blank" style="color: orange;">https://www.linkedin.com/in/dhritiraj-sengupta-ph-d-682648167</a><br>
              Email: <a href="mailto:dhritiraj@sklec.ecnu.edu.cn" style="color: orange;"> dhritiraj@sklec.ecnu.edu.cn </a>
            </p>
          </div>
        </div>
      </div>

      <div class="container">
        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/ZhuoWu.jpg">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Zhuo Wu</strong><br>
            <p>PhD, Assistant Professor <br>
              Guangzhou University, China <br>
              Links: <a href="http://geo.gzhu.edu.cn/info/1176/2196.htm" target="_blank" style="color: orange;">http://geo.gzhu.edu.cn/info/1176/2196.htm</a><br>
              Email: <a href="mailto:wuzhuo@gzhu.edu.cn" style="color: orange;"> wuzhuo@gzhu.edu.cn </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <h4 class="cos-strong">Regional Contact Person</h4>
    <div class="col-12 cos-modal2">
      <hr>
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/Suh-heeChoi.png">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Suh-hee Choi</strong><br>
            <strong>Region 1’s contact person</strong>
            <p>PhD, Assistant Professor<br>
              Kyunghee University, South Korean<br>
              Links: <a href="http://geo.khu.ac.kr/contents/bbs/bbs_content.html?bbs_cls_cd=002001002001&cid=19050709262587&no=12&bbs_type=G" target="_blank" style="color: orange;">http://geo.khu.ac.kr/contents/bbs/bbs_content.html?bbs_cls_cd=002001002001&cid=19050709262587&no=12&bbs_type=G</a><br>
              Email: <a href="mailto:suhhee@khu.ac.kr" style="color: orange;"> suhhee@khu.ac.kr </a>
            </p>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/Gulomjon Umirzakov.jpg">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Gulomjon Umirzakov</strong><br>
            <strong>Region 2’s contact person</strong>
            <p>PhD, Assistant Professor<br>
              National University of Uzbekistan, Uzbekistan<br>
              Links: <a href="http://spa.nuu.uz/eng/staff/146" target="_blank" style="color: orange;">http://spa.nuu.uz/eng/staff/146</a><br>
              Email: <a href="mailto:g.umirzakov@gmail.com" style="color: orange;"> g.umirzakov@gmail.com </a>
            </p>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/EmreKucu.png">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Mr. Ömer Emre Kuşcu
            </strong><br>
            <strong>Region 3’s contact person</strong>
            <p>
              Istanbul University, Turkey<br>
              Links: <a href="https://www.researchgate.net/profile/Oemer-Emre-Kuscu" target="_blank" style="color: orange;">https://www.researchgate.net/profile/Oemer-Emre-Kuscu</a><br> 
              Email: <a href="mailto:omeremrekuscu@ogr.iu.edu.tr
" style="color: orange;"> omeremrekuscu@ogr.iu.edu.tr
</a>
            </p>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/DebajitDatta.jpg">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Debajit Datta</strong><br>
            <strong>Region 4’s contact person</strong>
            <p>PhD, Assistant Professor<br>
              Jadavpur University, India<br>
              Links: <a href="http://www.jaduniv.edu.in/profile.php?uid=1073" target="_blank" style="color: orange;">http://www.jaduniv.edu.in/profile.php?uid=1073</a><br>
              Email: <a href="mailto:debajit.geo@gmail.com" style="color: orange;"> debajit.geo@gmail.com </a>
            </p>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/Snipaste_2022-11-05_18-41-36.png">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Vu Thi Bac</strong><br>
            <strong>Region 5’s contact person</strong>
            <p>PhD, Lecturer<br>
              University of Social Sciences & Humanities, Vietnam<br>
              Links: <a href="https://www.researchgate.net/profile/Bac-Vu-2" target="_blank" style="color: orange;">https://www.researchgate.net/profile/Bac-Vu-2</a><br>
              Email: <a href="mailto:vubac@hcmussh.edu.vn" style="color: orange;"> vubac@hcmussh.edu.vn </a>
            </p>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/XingHuang.png">
          </div>
          <div class="col-md-9 col-xs-12 m-center">
            <strong>Dr. Xing Huang</strong><br>
            <strong>Region 6’s contact person</strong>
            <p>PhD, Associate Professor<br>
              South China University of Technology, China<br>
              Links: <a href="http://www2.scut.edu.cn/architecture/2017/0406/c2894a160772/page.htm" target="_blank" style="color: orange;">http://www2.scut.edu.cn/architecture/2017/0406/c2894a160772/page.htm</a><br>
              Email: <a href="mailto:huangxing57@scut.edu.cn" style="color: orange;"> huangxing57@scut.edu.cn </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <h4 class="cos-strong">Office Assistant</h4>
    <hr>
    <div class="col-12 cos-modal2">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-xs-12">
            <img class="img-responsive" src="../assets/static/img/Members/zhangkai.jpg">
          </div>
          <div class="col-md-9 col-xs-12">
            <strong> Kai Zhang </strong><br>
            <p>Ph.D. Candidate<br>
              Nanjing Normal University, China <br>
              Email: <a href="mailto:zhangk2g@163.com" style="color: orange;"> zhangk2g@163.com </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoardMembers'
}
</script>

<style lang="less" scoped>
#BoardMembers {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

.img-responsive {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  max-width: 100%;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

h4 {
  color: #117450;
}

li {
  line-height: 2.2rem;
}

.row {
  align-items: center;
  margin: 2.3rem 0;
  line-height: 2rem;
}

p {
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}
</style>
