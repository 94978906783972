<template>
  <div id="MemberSpace">
    <div class="container">
      <h3>
        MemberSpace
      </h3>
      <div class="row">
        <!-- Personal Information -->
        <div class="col-md-5">
          <h4>Personal Information</h4>
          <hr>
          <div class="row info-row">
            <div class="col-md-6 left">
              Name:
            </div>
            <div class="col-md-6">
              {{this.memberInfo.memberName}}
            </div>
          </div>

          <div class="row info-row">
            <div class="col-md-6 left">
              Email:
            </div>
            <div class="col-md-6">
              {{this.memberInfo.memberEmail}}
            </div>
          </div>

          <div class="row info-row">
            <div class="col-md-6 left">
              Member register time:
            </div>
            <div class="col-md-6">
              {{this.memberInfo.memberRegisterTime}}
            </div>
          </div>

          <div class="row info-row">
            <div class="col-md-6 left">
              Member expiration time:
            </div>
            <div class="col-md-6">
              {{this.memberInfo.memberDueTime}}
            </div>
          </div>

          <div class="row info-row">
            <div class="col-md-6 left">
              Is over expiration time:
            </div>
            <div class="col-md-6">
              {{this.memberInfo.memberIsDue}}
            </div>
          </div>
        </div>

        <div class="col-md-2"></div>

        <!-- Personal Information -->
        <div class="col-md-5">
          <br>
          <h4>Personal Setting</h4>
          <br>
          <hr>
          <br>
          <el-button type="success" @click="Renew">Renew</el-button>
          <br>
          <p>Tip: Click the 'Renew' button to renew the membership for one more year.</p>

          <el-button type="success" @click="logOut">Log out</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  name: 'MemberSpace',
  data() {
    return {
      memberInfo: ''
    }
  },

  methods: {
    Renew() {
      const memberId = this.memberInfo.memberId
      console.log(memberId)

      const memberIdData = {
        memberId: memberId
      }
      let resData = null
      this.$http.post('http://39.98.210.144:8089/ygwg/member/renew', qs.stringify(memberIdData)).then(res => {
        console.log(res);
        resData = res.data
        if (resData != 'Please renew your membership one month before it expires.') {
          // 向服务器请求当前memberId所对应的用户的信息
          this.$http
            .post('http://39.98.210.144:8089/ygwg/in/Space', qs.stringify(memberIdData))
            .then(res => {
              console.log(res)
              const { data: resData } = res
              const memberId = resData[0]
              const memberName = resData[1]
              const memberEmail = resData[2]
              const memberRegisterTime = resData[3]
              const memberDueTime = resData[4]
              const memberIsDue = resData[5]

              let memberInfo = {
                memberId: memberId,
                memberName: memberName,
                memberEmail: memberEmail,
                memberRegisterTime: memberRegisterTime,
                memberDueTime: memberDueTime,
                memberIsDue: memberIsDue
              }
              sessionStorage.setItem('memberInfo', JSON.stringify(memberInfo))
              this.memberInit()
            })
            .then(() => {
              // 如果是会员用户,则跳转到会员中心
              window.location.href = '/MemberSpace'
            })
        }
        else {
          this.$alert('Please renew your membership one month before it expires.', 'Message', {
            confirmButtonText: 'OK',
            type: 'info'
          })
        
        }
      })
    },

    logOut() {
      this.$http.post('http://39.98.210.144:8089/ygwg/logout').then(function () {
        sessionStorage.clear()
        window.location.href = '/'
        location.reload()
        window.location.href = '/'
      })
    },

    memberInit() {
      this.memberInfo = JSON.parse(sessionStorage.getItem('memberInfo'))
    }
  },

  created() {
    this.memberInit()
  }
}
</script>

<style lang="less" scoped>
#MemberSpace {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  height: 700px;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
  text-align: center;
  margin-bottom: 3.3rem;
}

h4 {
  color: black;
  font-size: 1.2rem;
  font-weight: 1000;
  text-align: center;
  margin: 0.8rem 0rem;
}

hr {
  margin: 0;
}

p {
  font-size: 1.1rem;
}

.col-md-5 {
  border: #333333 dashed 1px;
  height: 20rem;
  border-radius: 1rem;
}

.info-row {
  line-height: 3.1rem;
}

.col-md-6 {
  font-size: 1.1rem;
}

.left {
  font-weight: bolder;
}

.el-button {
  padding: 0.7rem 1rem;
}
</style>