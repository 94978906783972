<template>
  <div>
    <div id="Awards">
      <div>
        <h3 class="cos-strong">Best Paper Awards</h3>
        <hr>
        <div>
          <p>The Best Paper Award (BPA) is presented to the individual(s) judged by an awards committee to have written the best paper appearing in the annual conference proceedings.<br></p>
          <p>Three BPAs will be awarded at each annual conference. Notice of BPA application will be announced along with the Annual Conference Notice.
          </p>
          <p>All applicants must be YGWG members. Membership Application Form can be found under the page of "Join Us" on this website. Membership applicants need to fill the forms and email the forms to the corresponding regional contact person.</p>
        </div>
      </div>
      <div>
        <h3 class="cos-strong">Young Scholar Award</h3>
        <hr>
        <div>
          <p>The Young Scholar Award (YSA) is presented to the individual(s) judged by an awards committee recognize his or her outstanding scientific achievements and contributions. Applicants needs to hold a PhD degree, and within seven years of graduation.<br></p>
          <p>Two YSAs will be awarded each year.<br></p>
          <p>Details regarding requirements and documents for application will be announced soon on this website.</p>
          <p>All applicants must be YGWG members. Membership Application Form can be found under the page of "Join Us" on this website. Membership applicants need to fill the forms and email the forms to the corresponding regional contact person.</p>
          <p></p>
          <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/e754fe3b-5fd7-4161-b6f2-c3f75710e5c2" class="greenColor">&nbsp;&nbsp;Young Scholar Award (YSA) Application Form.docx</a></i>
        </div>
      </div>

      <br>

      <div>
        <h3 class="cos-strong">Distinguished Service Award</h3>
        <hr>
        <div>
          <p>The Distinguished Service Award (DSA) is conferred by the working group on members of the working group who have given long and meritorious service to the working group or for particularly meritorious service of a shorter duration.</p>
          <p>One DSA will be awarded every two years.<br></p>
          <p>Please be aware that the deadline for submitting application for each year's DSA is June 1st 2022. Details regarding requirements and documents for application will be announced soon on this website.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Awards'
}
</script>

<style lang="less" scoped>
#Awards {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  text-align: justify;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

a {
  text-decoration: none;
}

.greenColor {
  color: #117450 !important;
}
</style>