<template>
  <div id="MembersPapers">
    <div>
      <div>
        <h3>Members' Papers</h3>
        <hr>
        <div class="cos-modal">
          <ul>
            <li><strong><a href="https://doi.org/10.1016/j.earscirev.2020.103223" target="_blank">Position paper: Open web-distributed integrated geographic modelling and simulation to enable broader participation and applications.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/f6104fd2-2ea6-4dbb-89c8-73b5fe5e2d99" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1016/j.jenvman.2012.04.013" target="_blank">Community based mangrove management: A review on status and sustainability.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/ad59fd30-438d-4da9-bb14-9f21687ffa13" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1016/j.apgeog.2017.12.015" target="_blank">Building beyond land: An overview of coastal land reclamation in 16 global megacities.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/9a984004-95c1-4fe2-8533-3361980f8109" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://ageconsearch.umn.edu/record/302165" target="_blank">ESTIMATION OF PHREATIC EVAPORATION IN IRRIGATION AGRICULTURE USING STABLE ISOTOPES.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/8b842fb1-b114-4752-913d-ac324a083142" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1007/s10980-019-00809-8" target="_blank">Future forest dynamics under climate change, land use change, and harvest in subtropical forests in Southern China.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/c1e54032-f727-4dcb-b7c7-bd245b5bf9c3" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1080/16078055.2016.1216886" target="_blank">Leisure attitude and satisfaction with leisure and life.proposing leisure prioritization and justification.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/1b86c80a-0a5a-4f1f-90c6-00550ddf0d13" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1016/j.future.2020.05.044" target="_blank">Participatory intercomparison strategy for terrestrial carbon cycle models based on a service-oriented architecture. Future Generation Computer Systems.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/526bc545-6ba5-43f6-825d-15b9bbe51610" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1016/j.apgeog.2015.02.002" target="_blank">Temporal stability of model parameters in crime rate analysis, An empirical examination.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/aa329b73-99f0-4b04-bce5-c5fc42866f69" target="_blank"> Download </a></i>
            <br><br>

            <li><strong><a href="https://doi.org/10.1016/j.jdmm.2018.02.005" target="_blank">Tourism communicative actions of sojourners and information recipients.</a></strong></li>
            <i class="el-icon-paperclip"><a href="http://175.27.137.60:8083/data/7d97ef22-3f1f-4b66-bc70-09d225e4bd81" target="_blank"> Download </a></i>
            <br><br>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MembersPapers'
}
</script>

<style lang="less" scoped>
#MembersPapers {
  font-size: 1.2rem;
  line-height: 2rem;
  height: 60rem;
  text-align: justify;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

h3 {
  color: #117450;
  font-size: 20px;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

a {
  text-decoration: none;
  color: #2375b3;
}
</style>