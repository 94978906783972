<template>
  <div id="_2020_1">
    <div id="The-general-election" class="cos-modal">
      <h3><strong>First online meeting of Young Geographer Working Group (YGWG) of AGA</strong></h3>
      <small class="home-date">June 27, 2020</small>
      <hr>
      <div class="pic">
        <img style="max-width: 100%;" src="../../../assets/static/img/Events/first online meeting.png">
      </div>
      <br>
      <ul>
        <li>
          Location:Zoom meeting<br>
          Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;June 27, 2020<br>
          Time:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14:00 Beijing Time<br><br>
        </li>
        <li><strong>ATTENDANCE</strong><br>
          <p>
            Min Chen, Xuanzi Zhang, Dhritiraj Sengupta, Debajit Datta, Gulomjon Umirzakov, Nguyen Van
            Hoang,<br>
            Suh-hee Choi, Xing Huang, Zhuo Wu, Li He, Xiaoyu He<br><br>
          </p>
        </li>
        <li><strong>AGENDA ITEMS</strong><br>
          <p>
            1. Learn the background of establishing YGWG<br>
            2. Discuss the draft of YGWG (AGA) constitution<br>
            3. Discuss website content of YGWG<br>
            4. Plan future activities<br><br>
          </p>
        </li>
        <li><strong>ACTION ITEMS</strong><br>
          <p style="line-height: 40px">
            1. The Chair, Prof. Dr. Min Chen, presented the background of establishing YGWG, as well as the purpose and aim of promoting YGWG activities.<br>
            2. The secretary, Dr. Li He, presented the draft of YGWG’s constitution. Dr. Sengupta, Dr. Hoang, Dr. Chen and Dr. Wu made few great suggestions regarding including more Asian countries in YGWG, and detailing membership rules.<br>
            3. The secretary, Dr. Li He, presented the draft of YGWG’s website design, and all attendees discussed website contents. For example, add links of other important conferences on the website, list papers from journals for which YGWG members serve as editorial members, add information of special issues/working paper/funding, language options of the website, organize best paper competition and distinguished scholar award.<br>
            4. Attendees discussed future activity plan, such as creating group mail list, building website, organizing online lecture series, and offering internship opportunities to undergraduate and graduate students.
            <br><br>
          </p>
        </li>
        <li><strong>OTHER NOTES</strong><br>
          No other notes.
        </li>
      </ul>

      <br>

      <div id="June-A-Position-Paper" class="cos-modal">
        <strong class="home-h">A Position Paper on Open Web-Distributed Integrated Geographic Modelling and Simulation to Enable Broader Participation and Applications</strong><br>
        <small class="home-date">JUNE 24, 2020<br></small>
        <hr>
        <p>Chair of YGWG of AGA joined forces to write a position paper on Open Web-Distributed Integrated Geographic Modelling and Simulation to Enable Broader Participation and Applications.<br><br></p>
        <p>The <a href="https://www.sciencedirect.com/science/article/pii/S0012825220302695" target="_blank">paper</a> has appeared on the Elsevier journal Earth-Science Reviews and it is open access.<br><br></p>
        <p style="line-height: 40px;">The abstract follows:<br>
          Integrated geographic modelling and simulation is a computational means to improve understanding of the environment. With the development of Service Oriented Architecture (SOA) and web technologies, it is possible to conduct open, extensible integrated geographic modelling across a network in which resources can be accessed and integrated, and further distributed geographic simulations can be performed. This open web-distributed modelling and simulation approach is likely to enhance the use of existing resources and can attract diverse participants. With this approach, participants from different physical locations or domains of expertise can perform comprehensive modelling and simulation tasks collaboratively. This paper reviews past integrated modelling and simulation systems, highlighting the associated development challenges when moving to an open web-distributed system. A conceptual framework is proposed to introduce a roadmap from a system design perspective, with potential use cases provided. The four components of this conceptual framework – a set of standards, a resource sharing environment, a collaborative integrated modelling environment, and a distributed simulation environment – are also discussed in detail with the goal of advancing this emerging field.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '2020-1'
}
</script>

<style lang="less" scoped>
#_2020_1 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  height: 2300px;
  text-align: justify;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  width: 100rem;
  display: table-cell;
  text-align: center;
}
</style>