<template>
  <div id="JoinUs">
    <div>
      <h3 class="cos-strong">Membership</h3>
      <hr>
      <div class="cos-modal">Members should be under the age of 45. YGWG members have access to the following benefits of membership:
      </div>
      <div class="cos-modal">
        <ul>
          <li>Participation in all A-YGWG conferences and awards competition;</li>
          <li>Joining the mailing list to receive updates;</li>
          <li>Free copies of A-YGWG international reports presented to A-YGWG assemblies and the published conference proceedings upon request;</li>
          <li>Access to training courses at times as the need is demonstrated and the financial support is available;</li>
          <li>Preferential treatment in exhibitions of A-YGWG;</li>
        </ul>
      </div>
    </div>

    <div>
      <h3 class="cos-strong">New Application&nbsp;/&nbsp;Renewal&nbsp;/&nbsp;Update</h3>
      <hr>
      <div class="cos-modal">Membership should be applied, renewed, and updated through an electronic application form.
        <br><br>
        Applicants should fill the forms and email to the corresponding regional contact person and make a copy to the secretary ( Dr.Kai Zhang, <a href="mailto:zhangk2g@163.com" style="color: orange;"> zhangk2g@163.com</a>). We will email you a unique Member ID. If you have registered this site, you can link the Member ID to your account.<br><br>
        You can renew your future membership online in the account.
        <br><br>
        Please refer to below for the email addresses of all regional contact persons.
        <br><br>
        <p>Region 1. </p>
        <p class="offset1">suhhee@khu.ac.k<br>Dr. Suh-hee Choi<br>Kyunghee University, South
          Korean<br><br></p>
        <p>Region 2. </p>
        <p class="offset1">g.umirzakov@gmail.com<br>Dr. Gulomjon Umirzakov<br>National University of
          Uzbekistan, Uzbekistan<br><br></p>
        <p>Region 3. </p>
        <p class="offset1">omeremrekuscu@ogr.iu.edu.tr<br>Mr. Ömer Emre Kuşcu<br>Istanbul University, Turkey<br><br></p>
        <p>Region 4. </p>
        <p class="offset1">debajit.geo@gmail.com<br>Dr. Debajit Datta<br>Jadavpur University,
          India<br><br></p>
        <p>Region 5. </p>
        <p class="offset1">ngvhoang@hcmussh.edu.vn<br>Dr. Nguyen Van Hoang<br>University of Social
          Sciences & Humanities.Vietnam<br><br></p>
        <p>Region 6. </p>
        <p class="offset1">huangxing57@scut.edu.cn<br>Dr. Xing Huang<br>South China University of
          Technology, China<br></p>
      </div>
      <div class="cos-modal">
        <br>
        <svg t="1651920825814" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1402" width="200" height="200">
          <path d="M448 876.8 806.4 512c128-128 57.6-262.4 0-313.6-57.6-57.6-192-128-320 0L172.8 512c-89.6 89.6-38.4 185.6 0 224 38.4 38.4 134.4 89.6 224 0l313.6-313.6c25.6-25.6 32-51.2 32-64 0-38.4-25.6-64-32-70.4-25.6-25.6-83.2-51.2-134.4 0L262.4 601.6c-12.8 12.8-12.8 32 0 44.8 12.8 12.8 32 12.8 44.8 0l313.6-313.6c19.2-19.2 32-12.8 44.8 0 0 0 25.6 19.2 0 44.8l-313.6 313.6c-64 64-121.6 12.8-134.4 0-12.8-12.8-64-70.4 0-134.4l313.6-313.6c108.8-108.8 217.6-12.8 224 0 12.8 12.8 108.8 115.2 0 224L403.2 832l0 0c-12.8 12.8-12.8 32 0 44.8C416 889.6 435.2 889.6 448 876.8L448 876.8 448 876.8C448 876.8 448 876.8 448 876.8 448 876.8 448 876.8 448 876.8z" p-id="1403" fill="#1a80bd"></path>
        </svg>
        <a href="https://gitee.com/MpLebron/data-repo/raw/master/AGA-YGWG%20Membership%20application%20form.docx"> Membership Application Form</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinUs'
}
</script>

<style lang="less" scoped>
#JoinUs {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;
  width: 65%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

hr {
  border: 0.1px solid;
  border-color: #eeeeee;
}

svg {
  height: 1.4rem;
  width: 1.4rem;
}

.offset1 {
  margin-left: 8.33333333%;
}

a {
  text-decoration: none;
  color: #117450;
}
</style>