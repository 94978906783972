<template>
  <div id="_2021_2">
    <div id="The-2nd-national-forum" class="cos-modal">
      <h3><strong>Prof. Dr. Antonio Paez's editoral introduction of the forthcoming special issue on Open Spatial Science in the Journal of Geographical Systems (JGS). </strong></h3>
      <div class="pic">
        <img style="max-width: 100%;" src="../../../assets/static/img/Events/prof-dr-antonio.png">
      </div>
      <i class="el-icon-paperclip"><a target="_blank" href="https://rdcu.be/cz3Wm">&nbsp;&nbsp;Open spatial sciences: an introduction</a></i>
    </div>
  </div>
</template>

<script>
export default {
  name: '_2021_2'
}
</script>

<style lang="less" scoped>
#_2021_2 {
  font-size: 1.2rem;
  line-height: 2rem;
  width: 65%;
  margin: 0 auto;
  position: relative;
  word-spacing: 0.3rem;
  margin-top: 2.2rem;
  line-height: 2rem;
  height: 1300px;
  text-align: justify;
}

h3 {
  color: #117450;
  font-size: 1.3rem;
  font-weight: 1000;
}

.home-date {
  font-weight: bold;
  color: grey;
}

a {
  text-decoration: none;
}

.pic {
  width: 100rem;
  display: table-cell;
  text-align: center;
}
</style>